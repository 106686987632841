const styles = {
  selected: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    color: 'green !important'
  },
  adminSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '8px',
    marginBottom: '10px',
    marginRight: '30px'
  },
  adminButton: {
    padding: 0,
    fontSize: 14,
    color: 'white',
    fontWeight: 600
  },
  spanStyle: {
    color: 'white',
    fontWeight: 200
  },
  chip: {
    height: '48px',
    alignItems: 'center',
    border: 'none',
    transition: 'all .2s ease-in-out',
    '& .MuiChip-label': {
      lineHeight: 0
    }
  },
  avatar: {
    margin: '8px 0 8px 0px !important',
    cursor: 'pointer'
  },
  popper: {
    // height: '100%',
    width: '384px',
    // maxHeight: '100%',
    overflowX: 'hidden'
  },
  list: (theme) => ({
    width: '100%',
    maxWidth: 350,
    minWidth: 300,
    borderRadius: '10px',
    [theme.breakpoints.down('md')]: {
      minWidth: '100%'
    },
    '& .MuiListItemButton-root': {
      mt: 0.5,
      '&:hover': {
        backgroundColor: '#F6F9FF'
      },
      '&.Mui-selected': {
        backgroundColor: '#D4E4FD'
      }
    }
  }),
  listItem: {
    display: 'flex',
    p: 0,
    gap: '24px',
    pb: '24px'
  },
  listItemBox: {
    display: 'grid',
    justifyContent: 'center'
  },
  avatarBox: {
    width: 75,
    height: 75,
    alignSelf: 'center',
    backgroundColor: '#2A76F4',
    color: 'white'
  },
  userBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: 1
  },
  personaBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    borderTop: '1px solid #D4E4FD',
    px: 0
  },
  personaTab: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column'
  },
  tabContext: {
    '.MuiTabs-flexContainer': {
      flexDirection: 'column'
    },
    '.Mui-selected': {
      backgroundColor: 'rgb(234, 241, 254)',
      color: 'black !important',
      fontWeight: 600,
      borderRadius: 2
    },
    '.MuiTabs-indicator': {
      display: 'none'
    }
  },
  gridSection: {
    borderTop: '1px solid #D4E4FD',
    py: 1
  },
  logoutButton: {
    mx: 0,
    px: 12,
    border: 2,
    borderRadius: 2,
    borderColor: 'red'
  },
  logoutText: {
    color: 'red'
  }
};

export default styles;
