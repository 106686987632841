import React, { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, Button, DialogActions, Divider, Grid, Typography } from '@mui/material';
import ProfileTabs from 'ui-component/Toggle/ProfileToggle';
import CloseIcon from '@mui/icons-material/Close';
import CustomizedSwitches from 'ui-component/buttons/CustomSwitch';
import PasswordChange from 'ui-component/forms/PasswordChange';
import ProfilePicSection from 'ui-component/forms/ProfilePicSection';

const notifications = [
  {
    id: 1,
    title: 'Mute Notifications',
    description: 'Play sounds for new notifications',
    isEnabled: false
  },
  {
    id: 2,
    title: 'Email Notifications',
    description: 'Receive email updates on comments, mention etc..',
    isEnabled: true
  },
  {
    id: 3,
    title: 'Push Notifications',
    description: 'Receive push notifications on new messages',
    isEnabled: false
  },
  {
    id: 4,
    title: 'Notification Sound',
    description: 'Play sounds for new notifications',
    isEnabled: false
  }
];
export default function ProfileSettingModal({ open, handleClose }) {
  const isManualLogin = useSelector((state) => state.SSOLogin.isLoggedin);

  const simpleTabData = useCallback(() => {
    if (!isManualLogin) {
      return [
        { label: 'Profile', value: 'profile' },
        { label: 'Password', value: 'pasword' }
      ];
    } else {
      return [{ label: 'Profile', value: 'profile' }];
    }
    // eslint-disable-next-line
  }, []);

  const [simpleTab, setSimpleTab] = useState('profile');

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          '& .MuiPaper-root': {
            width: '600px',
            height: '670px'
          }
        }}
      >
        <DialogTitle id="alert-dialog-title">
          <Grid item mx={2} xs={6} sm={6} justifyContent="space-between" display="flex" alignItems="center">
            <ProfileTabs data={simpleTabData()} selected={simpleTab} setSelected={(value) => setSimpleTab(value)} />
            <CloseIcon onClick={handleClose} sx={{ cursor: 'pointer' }} />
          </Grid>
        </DialogTitle>
        <DialogContent>
          <>
            {simpleTab === 'profile' ? (
              <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="100%">
                <ProfilePicSection />
              </Box>
            ) : simpleTab === 'notifications' ? (
              <Grid mb={18} mx={2}>
                <Typography sx={{ fontSize: 16, fontWeight: 600, my: 2 }}>Notifications</Typography>
                {notifications.map((item) => (
                  <Grid container key={item?.id} justifyContent="space-between" my={4}>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      <Typography sx={{ fontSize: 14, fontWeight: 500 }}>{item?.title}</Typography>
                      <Typography sx={{ fontSize: 14, fontWeight: 500, color: '#818690' }}>{item?.description}</Typography>
                    </Box>
                    <CustomizedSwitches checked={item?.isEnabled} />
                  </Grid>
                ))}
              </Grid>
            ) : (
              <PasswordChange />
            )}
          </>
        </DialogContent>
        <Divider sx={{ mx: 2, width: '530px', margin: 'auto' }} />
        <DialogActions sx={{ display: 'flex', justifyContent: 'start', px: '26px', py: 2 }}>
          <Button style={{ color: '#2A76F4' }} target="_blank" href={import.meta.env.VITE_API_HELP} rel="noopener noreferrer">
            Help center
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
