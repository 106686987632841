import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import './style.css';
import { styled, useTheme } from '@mui/material/styles';
import { AppBar, Box, CssBaseline, Toolbar, useMediaQuery, Grid } from '@mui/material';
import Header from './Header';
import Sidebar from './Sidebar';
import { drawerWidth } from 'store/constant';
import { SET_MENU } from 'store/actions';
import AuthFooter from 'layout/MainLayout/Footer';
import SettingSection from './Header/FAQ';
import { useLocation } from 'react-router-dom';
import FadeInDiv from 'ui-component/FadeInDiv';

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  ...theme.typography.mainContent,
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
  background: 'white',
  transition: theme.transitions.create(
    'margin',
    open
      ? {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen
        }
      : {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen
        }
  ),
  [theme.breakpoints.up('md')]: {
    marginLeft: open ? 0 : -(drawerWidth - 20),
    marginRight: open ? 0 : -(drawerWidth - 20),
    width: `calc(100% - ${drawerWidth}px)`
  },
  [theme.breakpoints.down('md')]: {
    marginLeft: '20px',
    width: `calc(100% - ${drawerWidth}px)`,
    padding: '16px'
  },
  [theme.breakpoints.down('sm')]: {
    marginLeft: '10px',
    width: `calc(100% - ${drawerWidth}px)`,
    padding: '16px',
    marginRight: '10px'
  }
}));

const MainLayout = ({ children }) => {
  const theme = useTheme();
  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
  const isSettingPage = useSelector((state) => state.page.isSettingPage);
  const leftDrawerOpened = useSelector((state) => state.customization.opened);
  const dispatch = useDispatch();
  const handleLeftDrawerToggle = () => {
    dispatch({ type: SET_MENU, opened: !leftDrawerOpened });
  };
  const location = useLocation();
  const shouldRenderAuthFooter = location.pathname !== '/ask-asato';

  return (
    <>
      {!isSettingPage ? (
        <Grid>
          <Grid item xs={12}>
            <Box sx={{ display: 'flex' }}>
              <CssBaseline />
              <AppBar
                enableColorOnDark
                position="fixed"
                color="inherit"
                elevation={0}
                sx={{
                  bgcolor: '#021A42',
                  transition: leftDrawerOpened ? theme.transitions.create('width') : 'none'
                }}
              >
                <Toolbar>
                  <Header handleLeftDrawerToggle={handleLeftDrawerToggle} />
                </Toolbar>
              </AppBar>

              {/* matchdownmd is true when the device is mobile */}
              {/* LeftdrawerOpened is true when the mobile sidebar is open */}
              <Sidebar drawerOpen={matchDownMd ? leftDrawerOpened : true} drawerToggle={handleLeftDrawerToggle} />

              <Main theme={theme} open={matchDownMd ? leftDrawerOpened : true}>
                <FadeInDiv>
                  {children}
                  <Outlet />
                </FadeInDiv>
              </Main>
            </Box>
          </Grid>

          {shouldRenderAuthFooter && (
            <Grid item xs={12} sx={{ m: 3, ml: 13, mt: 1 }}>
              <AuthFooter />
            </Grid>
          )}
        </Grid>
      ) : (
        <Grid>
          <AppBar
            enableColorOnDark
            position="fixed"
            color="inherit"
            elevation={0}
            sx={{
              bgcolor: '#021A42',
              transition: leftDrawerOpened ? theme.transitions.create('width') : 'none'
            }}
          >
            <Toolbar>
              <Header handleLeftDrawerToggle={handleLeftDrawerToggle} />
            </Toolbar>
          </AppBar>

          <Grid item xs={12} mt={10}>
            <SettingSection />
          </Grid>

          <Grid item xs={12} sx={{ m: 3, mx: 10, my: 4 }}>
            <AuthFooter />
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default MainLayout;
