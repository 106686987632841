import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const RequireAuth = ({ children }) => {
  const navigate = useNavigate();
  // const [lastActivityTime, setLastActivityTime] = useState(Date.now());

  useEffect(() => {
    const isAuthenticated = checkIfUserIsAuthenticated();

    if (!isAuthenticated) {
      navigate('/login');
    }
    // else {
    //   const activityTimer = setInterval(() => {
    //     const currentTime = Date.now();
    //     const inactiveTime = currentTime - lastActivityTime;

    //     if (inactiveTime > 60 * 60 * 1000 * 15) {
    //       clearUserData();
    //       navigate('/login');
    //     }
    //   }, 1000);

    //   const updateActivityTime = () => setLastActivityTime(Date.now());
    //   window.addEventListener('mousemove', updateActivityTime);
    //   window.addEventListener('keydown', updateActivityTime);

    //   return () => {
    //     clearInterval(activityTimer);
    //     window.removeEventListener('mousemove', updateActivityTime);
    //     window.removeEventListener('keydown', updateActivityTime);
    //   };
    // }
  }, [navigate]);

  const checkIfUserIsAuthenticated = () => {
    const isLoggedIn = localStorage.getItem('isLoggedIn') === 'true';
    const accessToken = localStorage.getItem('accessToken');
    return isLoggedIn || !!accessToken;
  };

  return <>{children}</>;
};

export default RequireAuth;
