import { Link, Typography, Stack } from '@mui/material';

const AuthFooter = () => (
  <Stack direction="row" justifyContent="space-between">
    <Typography variant="subtitle2" href="" target="_blank" underline="hover">
      &copy; 2024 - Asato. All rights reserved
    </Typography>
    <Typography variant="subtitle2" component={Link} href="https://asato.ai/" target="_blank" underline="hover" rel="noopener noreferrer">
      About Us
    </Typography>
  </Stack>
);

export default AuthFooter;
