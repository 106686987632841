import { Avatar, Grid, Typography, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  avatar: {
    backgroundColor: '#2A76F4',
    color: 'white',
    height: '157px',
    width: '157px',
    fontSize: '50px',
    fontWeight: 800
  },
  box: {
    display: 'flex',
    gap: 10,
    marginTop: 15
  },
  nameText: {
    fontSize: '16px',
    fontWeight: 600,
    display: 'block'
  },
  emailText: {
    fontSize: '14px',
    fontWeight: 500,
    display: 'block',
    padding: 6,
    color: '#818690'
  },
  link: {
    fontSize: '14px',
    fontWeight: 600,
    color: '#2A76F4',
    textDecoration: 'none',
    padding: 5
  }
}));

const ProfilePicSection = () => {
  const classes = useStyles();
  const storedAccounts = JSON.parse(localStorage.getItem('userDetails'));
  const user =
    storedAccounts && storedAccounts.user
      ? { displayName: storedAccounts.user.displayName, employeeEmailId: storedAccounts.user.employeeEmailId }
      : { displayName: '', employeeEmailId: '' };
  const initials = user?.displayName
    ?.split(' ')
    ?.map((namePart) => namePart[0])
    ?.join('')
    ?.toUpperCase();

  return (
    <>
      <Grid container direction="column" justifyContent="center" alignItems="center">
        <Avatar className={classes.avatar}>{initials}</Avatar>
        <Link to="#" className={classes.link}>
          {/* Edit */}
        </Link>

        <Box className={classes.box}>
          <Typography className={classes.nameText}>{user.displayName}</Typography>|
          <Typography sx={{ color: '#818690', fontSize: '16px', fontWeight: 500 }}>{storedAccounts.user.jobTitle}</Typography>
        </Box>
        <Typography className={classes.emailText}>{user.employeeEmailId}</Typography>
      </Grid>
    </>
  );
};

export default ProfilePicSection;
