import { lazy } from 'react';
import Loadable from 'ui-component/Loadable';
import MinimalLayout from 'layout/MinimalLayout';
import { Navigate } from 'react-router';
import ChangePassword from 'views/login/ChangeCreatePassword';
const Login = Loadable(lazy(() => import('views/login/index')));
const ForgotPassword = Loadable(lazy(() => import('views/login/ForgotPassword')));

import { allRoutes } from './routers';

const landingUrl = Array.isArray(allRoutes) ? allRoutes?.find((route) => route?.sidebar) : null;

localStorage.setItem('landingUrl', JSON.stringify(landingUrl?.path || '/home'));

const AuthenticationRoutes = {
  path: '/',
  element: <MinimalLayout />,
  children: [
    {
      path: '/',
      element: <Navigate to={landingUrl?.path || '/home'} />
    },
    {
      path: '/login',
      element: <Login />
    },
    {
      path: '/forgot-password',
      element: <ForgotPassword />
    },
    {
      path: '/change-password',
      element: <ChangePassword />
    },
    {
      path: '/create-password',
      element: <ChangePassword />
    }
  ]
};

export default AuthenticationRoutes;
