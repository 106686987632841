import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, Box, CircularProgress } from '@mui/material';
import { keycloakLogout } from 'api/api';
import { useDispatch } from 'react-redux';
import { disableLogin } from 'store/slices/SSOLogin';

const LogoutModal = ({ open, handleClose }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleLogout = async () => {
    setLoading(true);
    try {
      const response = await keycloakLogout();
      dispatch(disableLogin());
      localStorage.clear();
      sessionStorage.clear();
      handleClose();
      window.location.href = '/login';
      console.log('Logout successful:', response);
    } catch (error) {
      console.error('Logout failed:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle sx={{ fontSize: '18px', fontWeight: 700 }}>Are you Leaving ?</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to logout? All your unsaved data will be lost</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined" color="inherit" disabled={loading}>
            Cancel
          </Button>
          <Button
            onClick={handleLogout}
            style={{ backgroundColor: '#FB5555', color: 'white' }}
            variant="contained"
            disabled={loading}
            startIcon={loading && <CircularProgress size={20} color="inherit" />}
          >
            {loading ? 'Logging out...' : 'Logout'}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default LogoutModal;
