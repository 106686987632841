import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import { Avatar, Box, ButtonBase } from '@mui/material';
import LogoSection from '../LogoSection';
// import SearchSection from './SearchSection';
import ProfileSection from './ProfileSection';
// import NotificationSection from './NotificationSection';

import { IconMenu2 } from '@tabler/icons';

const Header = ({ handleLeftDrawerToggle }) => {
  const theme = useTheme();

  return (
    <>
      <Box
        sx={{
          gap: '1rem',
          width: 228,
          minHeight: '48px',
          display: 'flex',
          alignItems: 'center',
          [theme.breakpoints.down('md')]: {
            width: 'auto'
          }
        }}
      >
        <ButtonBase sx={{ borderRadius: '12px', overflow: 'hidden', display: { xs: 'block', md: 'none', lg: 'none' } }}>
          <Avatar
            variant="rounded"
            sx={{
              ...theme.typography.mediumAvatar,
              transition: 'all .2s ease-in-out',
              background: 'transparent',
              color: theme.palette.secondary.light,
              '&:hover': {
                // background: theme.palette.secondary.dark,
                // color: theme.palette.secondary.light
              }
            }}
            onClick={handleLeftDrawerToggle}
            color="inherit"
          >
            <IconMenu2 stroke={1.5} size="1.6rem" />
          </Avatar>
        </ButtonBase>
        <Box component="span" sx={{ display: { md: 'block' }, flexGrow: 1 }}>
          <LogoSection />
        </Box>
      </Box>

      <Box sx={{ flexGrow: 4 }} />
      <Box sx={{ flexGrow: 4 }} />
      {/* <SearchSection /> */}
      {/* <Box sx={{ flexGrow: 1 }} /> */}
      {/* <Divider flexItem sx={{ borderRightWidth: 3, backgroundColor: 'white', my: 1.5, mx: 2 }} /> */}

      <ProfileSection />
      {/* <NotificationSection /> */}
    </>
  );
};

Header.propTypes = {
  handleLeftDrawerToggle: PropTypes.func
};

export default Header;
