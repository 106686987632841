const Logo = ({ fill }) => {
  return (
    <svg width="99" height="20" viewBox="0 0 99 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="asato-logo 4" clipPath="url(#clip0_114_375)">
        <g id="logotype">
          <path
            id="Vector"
            d="M22.7709 8.49621C22.1595 7.94157 21.8011 7.20725 21.7569 6.42294H21.7552L21.7518 6.26201V0H15.8022V5.89173C15.8022 7.25256 16.8502 8.4509 18.2938 8.74151L18.3855 8.76025V9.39614H17.2612C16.3746 9.39614 15.5356 9.08367 14.897 8.51652L14.8885 8.50871C14.8885 8.50871 14.8783 8.49934 14.8715 8.49465C14.26 7.94157 13.9017 7.20725 13.8575 6.42137H13.8558L13.8524 6.26045V0H7.90281V5.89173C7.90281 7.25256 8.95075 8.4509 10.3944 8.74151L10.4861 8.76025V9.39614H9.36177C8.4616 9.39614 7.61408 9.07585 6.97207 8.49621C6.36063 7.94157 6.00226 7.20725 5.95811 6.42294H5.95641L5.95301 6.26201V0H0V6.4495C0 8.05875 1.42329 9.36646 3.17098 9.36646H5.14456V10.6914H3.17098C1.42159 10.6914 0 12.0006 0 13.6083V20H5.95131V13.7396C5.95131 12.01 7.4799 10.6039 9.36007 10.6039H10.4844V11.2366L10.3927 11.2554C8.94905 11.546 7.90111 12.7443 7.90111 14.1051V20.0016H13.8507V13.7411C13.8507 12.8943 14.2142 12.1022 14.8732 11.5069L14.8936 11.4882C15.5339 10.9195 16.3729 10.607 17.2578 10.607H18.3821V11.2397L18.2904 11.2585C16.8468 11.5491 15.7988 12.7474 15.7988 14.1083V20.0047H21.7484V13.7442C21.7484 12.0147 23.277 10.6085 25.1572 10.6085H25.1895V9.40083H25.1572C24.257 9.40083 23.4095 9.08054 22.7675 8.5009L22.7709 8.49621Z"
            fill={fill ? fill : 'white'}
          />
          <path
            id="Vector_2"
            d="M55.1702 9.293C55.0242 9.25551 54.535 9.14302 54.5044 9.1352C54.4654 9.12583 52.2829 8.63837 51.6952 8.47432C51.6171 8.45245 51.5441 8.42901 51.4727 8.40557C50.2499 8.0056 50.0851 7.4869 50.0851 7.03224C50.0851 5.96514 51.0261 5.44487 52.9623 5.44487H53.0812C54.9766 5.45424 55.8904 6.00576 56.1417 7.28847L56.2131 7.64938H58.3616L58.3259 7.18067C58.1476 4.81367 56.2759 3.50127 53.0591 3.48877H52.8672C49.6282 3.48877 47.6954 4.80429 47.6954 7.00881C47.6954 8.54619 48.7569 9.6836 50.773 10.3007C51.4642 10.5117 53.5261 10.9319 53.9898 11.0413C55.5863 11.3882 56.2538 11.6928 56.2538 12.6849C56.2538 13.3849 55.8581 14.5582 53.2017 14.5582H52.9351C50.4724 14.516 49.7828 13.4896 49.6384 12.6349L49.5756 12.2631H47.3201L47.354 12.7302C47.5256 15.0707 49.613 16.4846 52.9419 16.5128H53.1763C56.6462 16.5128 58.7166 15.0738 58.7166 12.6615C58.7166 10.5007 57.0045 9.75703 55.1685 9.28988L55.1702 9.293Z"
            fill={fill ? fill : 'white'}
          />
          <path
            id="Vector_3"
            d="M91.9187 3.49365C88.014 3.49365 84.8379 6.4153 84.8379 10.0072C84.8379 13.5991 88.014 16.5208 91.9187 16.5208C95.8234 16.5208 98.9994 13.5991 98.9994 10.0072C98.9994 6.4153 95.8234 3.49365 91.9187 3.49365ZM91.9187 14.3928C89.2895 14.3928 87.1512 12.4258 87.1512 10.0072C87.1512 7.58865 89.2895 5.62161 91.9187 5.62161C94.5478 5.62161 96.6862 7.58865 96.6862 10.0072C96.6862 12.4258 94.5478 14.3928 91.9187 14.3928Z"
            fill={fill ? fill : 'white'}
          />
          <path
            id="Vector_4"
            d="M80.1647 0.00732422H77.8124V3.61954H75.832V5.80687H77.8124V11.6142C77.7988 11.9501 77.7971 13.6906 79.1151 14.9812C80.0204 15.867 81.2942 16.317 82.8975 16.317H84.0949V14.1531H82.8975C81.9753 14.1531 81.2823 13.9359 80.8373 13.5063C80.1223 12.8141 80.163 11.7142 80.163 11.6939V5.80531H84.1051V3.61798H80.1647V0.00732422Z"
            fill={fill ? fill : 'white'}
          />
          <path
            id="Vector_5"
            d="M71.9756 5.20016V5.84542H71.5272C71.5272 5.84542 70.4589 3.50342 67.2149 3.50342C63.3153 3.50342 60.1426 6.42194 60.1426 10.0092C60.1426 13.5964 63.3119 16.5118 67.2098 16.5149C67.2115 16.5149 67.2132 16.5149 67.2149 16.5149C70.4606 16.5149 71.5272 14.1729 71.5272 14.1729H71.9756V16.3602H74.2854V3.6331H71.9756V5.20172V5.20016ZM67.118 14.3901C64.4923 14.3901 62.3556 12.4246 62.3556 10.0092C62.3556 7.59372 64.4923 5.62825 67.118 5.62825C69.7438 5.62825 71.8805 7.59372 71.8805 10.0092C71.8805 12.4246 69.7438 14.3885 67.118 14.3885V14.3901Z"
            fill={fill ? fill : 'white'}
          />
          <path
            id="Vector_6"
            d="M42.8779 5.20016V5.84542H42.4295C42.4295 5.84542 41.3612 3.50342 38.1172 3.50342C34.2176 3.50342 31.0449 6.42194 31.0449 10.0092C31.0449 13.5964 34.2142 16.5118 38.1121 16.5149C38.1138 16.5149 38.1155 16.5149 38.1172 16.5149C41.3629 16.5149 42.4295 14.1729 42.4295 14.1729H42.8779V16.3602H45.1878V3.6331H42.8779V5.20172V5.20016ZM38.0204 14.3901C35.3946 14.3901 33.258 12.4246 33.258 10.0092C33.258 7.59372 35.3946 5.62825 38.0204 5.62825C40.6462 5.62825 42.7828 7.59372 42.7828 10.0092C42.7828 12.4246 40.6462 14.3885 38.0204 14.3885V14.3901Z"
            fill={fill ? fill : 'white'}
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_114_375">
          <rect width="99" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Logo;
