import React from 'react';
import { makeStyles } from '@mui/styles';
import DotLoader from './Loader';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '80vh'
  }
}));

const LoadingScreen = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <DotLoader />
    </div>
  );
};

export default LoadingScreen;
