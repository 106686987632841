import React, { useState, useEffect } from 'react';
import WestIcon from '@mui/icons-material/West';
import { styled } from '@mui/material/styles';
import { Box, Button, Grid, Paper, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { changeToSettingPage } from 'store/slices/PageSlice';
import SearchBar from 'ui-component/SearchBar/HelpSearch';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FadeInDiv from 'ui-component/FadeInDiv';
import Collapse from '@mui/material/Collapse';

const MuiAccordion = styled((props) => (
  <Accordion disableGutters elevation={0} square TransitionComponent={SlowMotionCollapse} {...props} />
))(() => ({
  border: `1px solid #D4E4FD`,
  padding: '15px 0',
  borderRadius: 8,
  margin: '10px 0',
  '&:not(:last-child)': {
    //   borderBottom: 0,
  },
  '&::before': {
    display: 'none'
  },
  '&:focus, &:focus-within': {
    backgroundColor: '#F4F8FE',
    border: `1px solid #2A76F4`
  }
}));

const SlowMotionCollapse = React.forwardRef(function SlowMotionCollapse(props, ref) {
  return <Collapse ref={ref} {...props} timeout={400} />;
});

const SettingSection = () => {
  const dispatch = useDispatch();
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredAccordionDetails, setFilteredAccordionDetails] = useState([]);
  const [noResults, setNoResults] = useState(false);

  const onBack = () => {
    dispatch(changeToSettingPage(false));
  };

  const accordionDetails = [
    {
      id: 1,
      title: 'Can I customize the layout of the application dashboard based on my preferences?',
      description:
        'Yes, you can customize the dashboard by clicking on the "Customize Dashboard" option. Drag and drop widgets, rearrange modules, and save your personalized dashboard view'
    },
    {
      id: 2,
      title: 'How do I add new users to the enterprise application?',
      description: 'Access Managment.'
    },
    {
      id: 3,
      title: 'What are the different user roles, and how can I modify permissions?',
      description: 'Executive, User, Admin.'
    },
    {
      id: 4,
      title: 'Can I track user activity and login history within the application?',
      description: 'No.'
    },
    {
      id: 5,
      title: 'Can I create a reports to incorporate data of Apps, Users, Cost ?',
      description: 'Yes.'
    }
  ];

  const filterAccordionDetails = () => {
    if (!searchQuery) {
      setFilteredAccordionDetails(accordionDetails);
      setNoResults(false);
    } else {
      const filteredDetails = accordionDetails.filter(
        (item) =>
          item.title.toLowerCase().includes(searchQuery.toLowerCase()) || item.description.toLowerCase().includes(searchQuery.toLowerCase())
      );
      if (filteredDetails.length === 0) {
        setNoResults(true);
      } else {
        setNoResults(false);
        setFilteredAccordionDetails(filteredDetails);
      }
    }
  };

  useEffect(() => {
    filterAccordionDetails();
    // eslint-disable-next-line
  }, [searchQuery]);

  return (
    <FadeInDiv>
      <main className="settingSection">
        <Grid container justifyContent="center" sx={{ flexGrow: 1, my: 2 }} spacing={3}>
          <Grid item lg={8} md={8} sm={10}>
            <Box component={'div'} sx={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }} onClick={onBack}>
              <WestIcon size="16" sx={{ color: 'white' }} />
              <Typography variant="bodyLg" color={'white'} sx={{ fontSize: '16px', fontWeight: '600', ml: 1 }}>
                {'Help Center'}
              </Typography>
            </Box>
            <Grid item lg={12} md={12} xs={12} mt={6} sx={{ display: 'flex', justifyContent: 'center' }}>
              <Paper
                elevation={3}
                sx={{
                  height: '20svh',
                  width: '60lvh',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: 2
                }}
              >
                <Typography sx={{ fontSize: 19, fontWeight: 700, fontFamily: 'Inter' }}>Welcome, How can i help you ?</Typography>
                <SearchBar onSearch={(query) => setSearchQuery(query)} />
              </Paper>
            </Grid>

            <Grid my={8}>
              <Typography sx={{ fontSize: 32, fontWeight: 700, fontFamily: 'Inter', mb: '20px' }}>FAQ & Troubleshooting</Typography>

              {noResults ? (
                <>
                  <Typography variant="h4" sx={{ mb: 1 }}>
                    No results found.
                  </Typography>
                  <Typography variant="body1">
                    If you still have questions, please feel free to email us at{' '}
                    <a href="mailto:your-email@example.com" style={{ color: '#5694ff', textDecoration: 'underline' }}>
                      support@asato.ai
                    </a>
                    , Or click the &quot;Contact Us&quot; button below to reach out.
                  </Typography>
                </>
              ) : (
                filteredAccordionDetails.map((item) => (
                  <MuiAccordion key={item?.id}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={`panel${item?.id}-content`}
                      id={`panel${item?.id}-header`}
                    >
                      <Typography sx={{ fontSize: 18, fontWeight: 700, fontFamily: 'Inter' }}>{item?.title}</Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ fontSize: 16, fontWeight: 400, color: '#434C5B' }}>{item?.description}</AccordionDetails>
                  </MuiAccordion>
                ))
              )}
            </Grid>

            <Grid item lg={12} md={12} xs={12} my={8} sx={{ display: 'flex', justifyContent: 'center' }}>
              <Paper
                elevation={3}
                sx={{
                  height: '30svh',
                  width: '-webkit-fill-available',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: 2,
                  backgroundColor: '#5694ff'
                }}
              >
                <Typography sx={{ fontSize: 20, fontWeight: 700, fontFamily: 'Inter', color: 'white' }}>Have other question?</Typography>
                <Typography sx={{ fontSize: 16, fontWeight: 400, fontFamily: 'Inter', color: '#e0e0e0' }}>
                  Reach out with questions anytime!
                </Typography>
                <Button
                  variant="outlined"
                  sx={{ color: 'white', borderColor: 'white' }}
                  target="_blank"
                  href={import.meta.env.VITE_API_HELP}
                  rel="noopener noreferrer"
                >
                  Contact Us
                </Button>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </main>
    </FadeInDiv>
  );
};

export default SettingSection;
