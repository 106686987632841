import { createTheme } from '@mui/material/styles';

// assets
import colors from 'assets/scss/_themes-vars.module.scss';

// project imports
import componentStyleOverrides from './compStyleOverride';
import themePalette from './palette';
import themeTypography from './typography';

/**
 * Represent theme style and structure as per Material-UI
 * @param {JsonObject} customization customization parameter object
 */

export const theme = (customization) => {
  const color = colors;

  const themeOption = {
    colors: color,
    heading: color.grey900,
    paper: color.paper,
    backgroundDefault: color.paper,
    background: color.primaryLight,
    darkTextPrimary: color.grey700,
    darkTextSecondary: color.grey500,
    textDark: color.grey900,
    textHeader: color.greyLight,
    menuSelected: color.secondaryDark,
    menuSelectedBack: color.secondaryLight,
    divider: color.grey200,
    borderRadius: 12,
    customization
  };

  const themeOptions = {
    direction: 'ltr',
    palette: themePalette(themeOption),
    mixins: {
      toolbar: {
        minHeight: '48px',
        padding: '16px',
        '@media (min-width: 600px)': {
          minHeight: '48px'
        }
      }
    },
    overrides: {
      '@global': {
        '*::-webkit-scrollbar': {
          width: '10px',
          height: '10px'
        },
        '*::-webkit-scrollbar-track': {
          background: 'blue'
        },
        '*::-webkit-scrollbar-thumb': {
          background: 'transparent',
          borderRadius: '6px',
          border: '2px solid transparent',
          transition: 'background 0.3s ease, border 0.3s ease'
        },
        '*:hover::-webkit-scrollbar-thumb': {
          background: 'rgba(0,0,0,0.3)',
          border: '2px solid transparent'
        },
        '*:hover::-webkit-scrollbar': {
          width: '10px',
          height: '10px'
        },
        body: {
          scrollbarWidth: 'thin',
          scrollbarColor: 'transparent transparent'
        },
        'body:hover': {
          scrollbarColor: 'rgba(0,0,0,0.3) rgba(0,0,0,0.1)'
        }
      }
    },
    typography: themeTypography(themeOption)
  };

  const themes = createTheme(themeOptions);
  themes.components = componentStyleOverrides(themeOption);

  return themes;
};

export default theme;
