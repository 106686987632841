import React, { useState, useEffect } from 'react';
import { Box, Paper, Typography, TextField, Button, InputAdornment, IconButton, CircularProgress, Grid } from '@mui/material';
import { Visibility, VisibilityOff, Circle } from '@mui/icons-material';
import { resetPassword } from '../../api/api';
import { useNavigate, useLocation } from 'react-router-dom';

const ChangePassword = () => {
  const textStyle = {
    color: '#353D4D',
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '18px',
    letterSpacing: '0.03em',
    textAlign: 'left'
  };
  const navigate = useNavigate();
  const location = useLocation();
  const isCreatePassword = location.pathname === '/create-password';
  const pageTitle = isCreatePassword ? 'Create Password' : 'Reset your password';
  const resetButton = isCreatePassword ? 'Create Password' : 'Reset Password';
  const successMessage = isCreatePassword ? 'Password created successfully!' : 'Password reset successfully!';
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordError, setPasswordError] = useState('');
  const [isPasswordFocused, setIsPasswordFocused] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [validation, setValidation] = useState({
    minLength: false,
    number: false,
    specialChar: false,
    uppercase: false
  });

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const token = params.get('token');
    if (!token) {
      navigate('/forgot-password');
    }
  }, [location.search, navigate]);

  const updateValidation = (password) => {
    setValidation({
      minLength: password.length >= 8,
      number: /\d/.test(password),
      specialChar: /[!@#$%^&*(),.?":{}|<>-]/.test(password),
      uppercase: /[A-Z]/.test(password)
    });
  };

  const handlePassword = (e) => {
    const newPassword = e.target.value;
    setNewPassword(newPassword);
    updateValidation(newPassword);
    if (!newPassword) setPasswordError('Password is required');
    else if (!validation.minLength || !validation.number || !validation.specialChar || !validation.uppercase) {
      setPasswordError('Password does not meet requirements');
    } else {
      setPasswordError('');
    }
  };

  const handleConfirmPassword = (e) => {
    setConfirmPassword(e.target.value);
    if (e.target.value !== newPassword) setPasswordError('Passwords do not match');
    else setPasswordError('');
  };

  const handlePasswordChange = async () => {
    if (
      !validation.minLength ||
      !validation.number ||
      !validation.specialChar ||
      !validation.uppercase ||
      newPassword !== confirmPassword
    ) {
      return;
    }
    setIsLoading(true);
    try {
      const params = new URLSearchParams(location.search);
      const token = params.get('token');
      const id = params.get('id');
      if (!token) {
        throw new Error('Token not found');
      } else if (!id) {
        throw new Error('id not found');
      }
      const encodedPassword = newPassword;
      await resetPassword(token, id, encodedPassword);
      setSuccess(true);
      setError(null);
      setTimeout(() => {
        navigate('/login');
      }, 3000);
    } catch (error) {
      setError(error.error || 'An error occurred while changing the password.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Grid className="test1" item xs={12} md={5}>
      <Box position="absolute" left={0} top={0} padding={2}>
        <img src="asato.svg" alt="Logo" style={{ width: '100px', height: 'auto' }} />
      </Box>
      <Box height="100vh" display="flex" alignItems="center" justifyContent="center">
        <Paper
          style={{
            padding: '20px',
            maxWidth: '450px',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          {!success && (
            <Typography variant="h1" gutterBottom style={{ alignSelf: 'flex-start', marginBottom: '50px' }}>
              {pageTitle}
            </Typography>
          )}

          <form style={{ width: '100%' }} onSubmit={(e) => e.preventDefault()}>
            <Box marginBottom={2}>
              {success ? (
                <Typography sx={{ textAlign: 'center' }} variant="h2" color="success">
                  {successMessage}
                </Typography>
              ) : (
                <>
                  <Typography variant="body2" color="textSecondary" style={textStyle}>
                    {isCreatePassword ? 'Enter your password' : 'Enter new password'}
                  </Typography>
                  <TextField
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    placeholder={isCreatePassword ? 'Enter your password' : 'Enter your new password'}
                    type={showNewPassword ? 'text' : 'password'}
                    value={newPassword}
                    onChange={handlePassword}
                    error={!!passwordError}
                    helperText={passwordError}
                    onFocus={() => setIsPasswordFocused(true)}
                    required
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setShowNewPassword(!showNewPassword)}
                            edge="end"
                            aria-label="toggle password visibility"
                          >
                            {showNewPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                    InputLabelProps={{
                      shrink: isPasswordFocused || !!newPassword
                    }}
                  />

                  <Box mt={4} />

                  <Typography variant="body2" color="textSecondary" style={textStyle}>
                    {isCreatePassword ? 'Repeat Password' : 'Confirm new password'}
                  </Typography>
                  <TextField
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    placeholder={isCreatePassword ? 'Repeat Password' : 'Confirm new password'}
                    type={showConfirmPassword ? 'text' : 'password'}
                    value={confirmPassword}
                    onChange={handleConfirmPassword}
                    error={!!passwordError}
                    helperText={passwordError}
                    onFocus={() => setIsPasswordFocused(true)}
                    required
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                            edge="end"
                            aria-label="toggle password visibility"
                          >
                            {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                    InputLabelProps={{
                      shrink: isPasswordFocused || !!confirmPassword
                    }}
                  />

                  <Grid container spacing={1} sx={{ mb: 5, mt: 1 }}>
                    <Grid
                      item
                      xs={6}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        color: validation.minLength ? 'black' : '#a9a9a9'
                      }}
                    >
                      <Circle sx={{ fontSize: 8, mr: 1, color: validation.minLength ? '#2A76F4' : '#a9a9a9' }} /> Eight characters minimum
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        color: validation.number ? 'black' : '#a9a9a9'
                      }}
                    >
                      <Circle sx={{ fontSize: 8, mr: 1, color: validation.number ? '#2A76F4' : '#a9a9a9' }} /> One number
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        color: validation.specialChar ? 'black' : '#a9a9a9'
                      }}
                    >
                      <Circle sx={{ fontSize: 8, mr: 1, color: validation.specialChar ? '#2A76F4' : '#a9a9a9' }} /> One special character
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        color: validation.uppercase ? 'black' : '#a9a9a9'
                      }}
                    >
                      <Circle sx={{ fontSize: 8, mr: 1, color: validation.uppercase ? '#2A76F4' : '#a9a9a9' }} /> One uppercase character
                    </Grid>
                  </Grid>

                  <Box mt={4} />
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    type="button"
                    disabled={isLoading || !newPassword || !confirmPassword || passwordError}
                    onClick={handlePasswordChange}
                    sx={{
                      borderRadius: 2,
                      backgroundColor: isLoading ? '#B3B6BC' : '#1B2536',
                      height: '48px',
                      ':hover': {
                        backgroundColor: '#215DC6'
                      }
                    }}
                  >
                    {isLoading ? <CircularProgress size={24} color="inherit" /> : resetButton}
                  </Button>
                </>
              )}
            </Box>
            {error && (
              <Typography variant="body2" color="error" sx={{ mt: 2 }}>
                {error}
              </Typography>
            )}
            <Box textAlign="center" marginTop={2}>
              <Typography variant="body2" style={{ cursor: 'pointer', color: '#2A76F4' }} onClick={() => navigate('/login')}>
                Back to Login
              </Typography>
            </Box>
          </form>
        </Paper>
      </Box>
    </Grid>
  );
};

export default ChangePassword;
