import React from 'react';
import { Grid, Container } from '@mui/material';
import ChangePassword from './ChangePassword';

const ChangeCreatePassword = () => {
  return (
    <Container maxWidth="sl" style={{ padding: '0' }}>
      <Grid container justifyContent="center">
        <ChangePassword />
      </Grid>
    </Container>
  );
};

export default ChangeCreatePassword;
