import Home from '../../../../assets/images/home-2.svg?react';
import Assets from '../../../../assets/images/home-hashtag.svg?react';
import Users from '../../../../assets/images/people.svg?react';
import Cost from '../../../../assets/images/dollar-square.svg?react';
import Link from '../../../../assets/images/link.svg?react';

import Tracker from '../../../../assets/images/chart-2.svg?react';
import Access from '../../../../assets/images/user-octagon.svg?react';
import Report from '../../../../assets/images/graph.svg?react';

import Ask from '../../../../assets/images/Vector.svg?react';

export const sidebarData = [
  {
    id: 1,
    name: 'Home',
    svg: <Home />,
    to: '/home'
  },
  {
    id: 2,
    name: 'Link',
    svg: <Link />,
    to: '/link'
  },
  {
    id: 3,
    name: 'Assets',
    svg: <Assets />,
    to: '/apps'
  },
  {
    id: 4,
    name: 'Usage',
    svg: <Users />,
    to: '/usage'
  },
  {
    id: 5,
    name: 'Costs',
    svg: <Cost />,
    to: '/cost'
  }
];

export const sidebarData1 = {
  Executive: [
    {
      id: 1,
      name: 'Tracker',
      svg: <Tracker />,
      to: '/report-tracker?tab=insight'
    }
  ],

  User: [
    {
      id: 1,
      name: 'Tracker',
      svg: <Tracker />,
      to: '/report-tracker?tab=insight'
    },
    {
      id: 3,
      name: 'Report',
      svg: <Report />,
      to: '/report'
    }
  ],

  Admin: [
    {
      id: 2,
      name: 'Access',
      svg: <Access />,
      to: '/access'
    }
  ]
};

export const askAsato = [
  {
    id: 1,
    name: 'Ask Asato',
    svg: <Ask />,
    to: '/ask-asato'
  }
];

export const sidebarIcons = {
  Home,
  Link,
  Assets,
  Users,
  Cost,
  Tracker,
  Access,
  Report,
  Ask
};
